// referenced in src/styles.scss

.mat-menu-item-submenu-trigger.menu-arrow-left {
  padding-right: 16px;
  padding-left: 30px;
}

.mat-menu-item-submenu-trigger.menu-arrow-left::after {
  right: auto;
  left: 14px;
  border-width: 5px 5px 5px 0;
  border-color: transparent currentColor transparent transparent;
}

button.mat-menu-item {
  height: 24px;
  line-height: 24px;
}

.mat-menu-panel {
  max-width: 768px !important;
}
