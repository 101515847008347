// referenced in src/styles.scss

.details-pane-tab-group {
  width: 648px;
}

.details-pane-tab-group .mat-tab-header {
  background: #f5f5f5;
  border-bottom: none;
  box-shadow: 0 .5px .25px -.25px rgba(0, 0, 0, .1), 0 .25px .25px 0 rgba(0, 0, 0, .14), 0 .25px .75px 0 rgba(0, 0, 0, .12);
  z-index: 2;
}

.details-pane-tab-group .mat-tab-labels {
  justify-content: center;
}

.details-pane-tab-group .mat-tab-label {
  padding: 0;
  max-width: 108px;
  min-width: 108px;
}

.details-pane-tab-group .mat-tab-body-wrapper {
  flex-grow: 1;
}


// same as styled-scroll
.details-pane-tab-group .mat-tab-body-content::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background: #f5f5f5;
}

.details-pane-tab-group .mat-tab-body-content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, .16);
}

.details-pane-tab-group .mat-tab-body-content::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, .20);
}

.details-pane-tab-group .mat-tab-body-content::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, .24);
}
