// referenced in src/styles.scss

.slim.mat-form-field .mat-form-field-wrapper {
  padding-bottom: 0;
}

.slim.mat-form-field .mat-form-field-underline {
  bottom: 0;
}

.slim.mat-form-field .mat-form-field-flex {
  padding: .5em .5em 0 .75em !important;
}

.slim.mat-form-field .mat-form-field-infix {
  padding: 0.25em 0 0.5em 0 !important;
}

.slim.no-label.mat-form-field .mat-form-field-infix {
  border-top: none;
}

.slim.no-label.mat-form-field .mat-select-arrow-wrapper {
  transform: translateY(-10%);
}