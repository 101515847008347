// referenced in src/styles.scss

.styled-scroll::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background: #f5f5f5;
}

.styled-scroll::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, .16);
}

.styled-scroll::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, .20);
}

.styled-scroll::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, .24);
}
