/* NEVER import this file into your components, if you want specifc colors import 'src/theme' */
/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
@import '@angular/material/theming';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Plus imports for other components in your app.
@import "https://fonts.googleapis.com/icon?family=Material+Icons"; // material icons
@import "https://fonts.googleapis.com/css?family=Roboto:300,400,500"; // Roboto
@import "https://js.arcgis.com/4.23/@arcgis/core/assets/esri/themes/light/main.css"; // ArcGIS
@import "./styles/esri-overrides.scss";
@import "./styles/styled-scroll.scss";
@import "./styles/filter-chips.scss";
@import "./styles/mat-menu-overrides.scss";
@import "./styles/mat-paginator-overrides.scss";
@import "./styles/mat-checkbox-overrides.scss";
@import "./styles/details-pane-tabs.scss";
@import "./styles/slim-mat-form-field.scss";
@import "./styles/mat-stepper-overrides.scss";
@import 'src/theme'; // used for importing specific colors

// (styles that need to be loaded prior to app init are in assets/init-styles.css)

// custom theme
$primary-color-set: (
  50: #E8EAF6,
  100: #C5CBF0,
  200: #A2ACEB,
  300: #7E8EE5,
  400: #5B6FE0,
  500: #3850DA,
  600: #3146C3,
  700: #293DAC,
  800: #223395,
  900: #1A297E,
  A100: #8c9eff,
  A200: #536dfe,
  A400: #3d5afe,
  A700: #304ffe,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);
$candy-app-primary: mat.define-palette($primary-color-set);
$candy-app-accent: mat.define-palette($primary-color-set, A100, A100, A400);

// The warn palette is optional (defaults to red).
$candy-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat.define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($candy-app-theme);

/* Init Styles */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  overflow: hidden;
  background: #f9faf6;
  border-top: 32px solid #fff;
}

.iowadot-logo {
  height: 24px;
  width: 132px;
  opacity: 0.62;
  margin: 0 3px;
}

.map {
  position: absolute;
  top: 0;
  bottom: 408px;
  left: 0;
  right: 0;
  z-index: 100;
}

.table-pane-standin {
  height: 408px;
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 200;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
  0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

/* added by init-auth */
.user-indicator,
.version-indicator,
.environment-indicator,
.services-indicator {
  display: flex;
  height: 24px;
  color: #3850da;
  font-size: 12px;
  cursor: pointer;
  border-radius: 6px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 8px;
  align-items: center;
  top: 4px;
}

.version-indicator:hover,
.services-indicator:hover {
  background-color: rgba(56, 80, 218, 0.25);
}

.environment-indicator {
  cursor: default;
  background-color: rgba(56, 80, 218, 0.50);
  color: white;
}

.mat-form-error {
  color: $error-red;
}

// a very thin shadow (used on table and dialog headers)
.mat-elevation-hairline {
  box-shadow: 0 0.5px 0.25px -0.25px rgba(0, 0, 0, 0.1),
  0 0.25px 0.25px 0 rgba(0, 0, 0, 0.14), 0 0.25px 0.75px 0 rgba(0, 0, 0, 0.12);
}

// remove padding on all dialog containers
.mat-dialog-container {
  padding: 0 !important;
  background: none;
}

// generic dialogs
.generic-dialog-titlebar {
  height: 48px;
  min-height: 48px;
  display: flex;
  align-items: center;
  padding: 4px 4px;
  background: #f5f5f5;
  color: #616161;
}

.generic-dialog-title {
  font-size: 18px;
  padding-left: 12px;
  font-weight: 500;
}


.map-popup-dialog-panel .mat-dialog-container {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.map-popup-dialog-panel-hidden .mat-dialog-container {
  box-shadow: none !important;
}

.map-popup-dialog-panel-hidden .mat-dialog-container .search-box {
  background: red !important;
}

.map-popup-dialog-panel .mat-tab-header,
.deep-query-category-tabs .mat-tab-header {
  display: none;
}

// autocomplete suggestions (used in map search widget)
.mat-autocomplete-panel {
  border-radius: 4px;
  margin-top: 4px;
}

// width and height for mat-select that contains multiselect-list
.mat-select-panel.multiselect-list-panel {
  max-width: 768px !important;
  min-width: 768px !important;
  max-height: 384px !important;
  overflow: hidden !important;
}

// options on mat-select
.mat-option {
  height: 32px !important;
}

// limit on table header cell height
.mat-sort-header-container {
  max-height: 42px;
}

// make tooltip font size larger
.mat-tooltip {
  font-size: 14px;
}

// used in table footer when attribute table is loading
.gray-spinner circle {
  stroke: #616161;
}

.overflow-visible-menu-panel {
  overflow: visible !important;
}

.attr-tbl-dialog-panel > .mat-dialog-container {
  overflow: visible; // allows shadow from child component to be visible
}

.attr-tbl-dialog-extra-shadow {
  box-shadow: 0px 0px 32px 32px rgba(0, 0, 0, 0.25);
}

.mat-header-cell {
  font-size: 14px !important;
  color: #616161 !important;
}

.mat-tab-label-active {
  opacity: 1 !important;
}

.mat-tab-group.pins-filter-fields-tabs {
  flex-direction: column-reverse;
}

.pins-filter-fields-tabs .mat-tab-label {
  width: 50%;
  height: 32px;
}

.pins-filter-fields-tabs .mat-tab-header {
  border-bottom: none;
  z-index: 2;
  box-shadow: 0 0.5px 0.25px -0.25px rgba(0, 0, 0, 0.1),
  0 0.25px 0.25px 0 rgba(0, 0, 0, 0.14), 0 0.25px 0.75px 0 rgba(0, 0, 0, 0.12);
}

.pins-filter-fields-tabs .mat-ink-bar {
  bottom: initial;
  top: 0;
}

.mat-select-panel {
  max-width: 600px !important;
  max-height: 300px !important;
}

app-details-pane-body .mat-select-value-text,
app-details-pane-body .mat-input-element {
  color: rgba(0, 0, 0, 0.87) !important;
}

app-details-pane-body .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6) !important;
}

.basemap-gallery-dialog-panel {
  background: #fff;
}

.measure-widget-dialog-panel {
  background: #fff;
}

.basemap-gallery-dialog-panel .esri-basemap-gallery__item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.basemap-gallery-dialog-panel
.esri-basemap-gallery__item.esri-basemap-gallery__item--selected {
  background-color: $selection-bg;
  border-left-color: $primary-color;
}

.br-stage-table {
  background: none;
}

.br-stage-table tbody {
  background: #fff;
}

.br-stage-table .mat-header-row {
  height: 42px;
}

.mat-snack-bar-container {
  color: #fff;
}

.mat-select-value {
  user-select: all !important;
}

.mat-menu-panel.p-slider-menu {
  max-width: calc(100vw - 0px) !important; /* Full Width */
  overflow: hidden;
}

.menu-title {
  font-size: 14px;
  color: #616161;
}

/* Custom ngx-slider aka p-slider style */

.p-slider .ngx-slider .ngx-slider-bar {
  background: $selection-bg;
}

.p-slider .ngx-slider .ngx-slider-selection {
  background: $primary-color;
}

.p-slider .ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background: $primary-color;
  width: 12px;
  height: 12px;
  margin-left: 12px;
  top: -1px;
}

.p-slider .ngx-slider .ngx-slider-pointer {
  cursor: pointer;
  width: 32px;
  height: 32px;
  top: -14px;
  background: $primary-color;
  z-index: 3;
  border-radius: 16px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flex-row-no-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.flex-column-shrink {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 0 1;
}

.simple-flex-container {
  display: flex;
}

.flex-centered-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-end-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-start-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.mr-small {
  margin-right: 8px;
}

.ml-small {
  margin-left: 8px;
}

.mr-normal {
  margin-right: 16px;
}

.mr-large {
  margin-right: 32px;
}

.h100p {
  height: 100%;
}

.accent-container {
  background-color: $light-gray;
  border-radius: 4px;
}
