// referenced in src/styles.scss

filter-chip.active .mat-form-field .mat-input-element {
  caret-color: #fff;
}

filter-chip.active .mat-form-field .mat-select-placeholder {
  color: rgba(255, 255, 255, .64);
}

filter-chip.active .mat-form-field .mat-form-field-underline::before {
  background: #fff;
}

filter-chip.active .mat-form-field.mat-focused .mat-form-field-ripple {
  background: #fff;
}

filter-chip.active .mat-form-field .mat-select .mat-select-arrow {
  color: rgba(255, 255, 255, .64);
}

app-filter-chip .mat-form-field .mat-select .mat-select-arrow-wrapper {
  transform: translateY(-5%);
}

filter-chip.active .mat-form-field .mat-datepicker-toggle .mat-icon-button {
  color: rgba(255, 255, 255, .64);
}

filter-chip.active .mat-form-field .mat-select .mat-select-value {
  color: #fff;
}

app-filter-chip .mat-form-field .mat-select .mat-select-value {
  color: #616161;
}

app-filter-chip .mat-form-field .mat-form-field-ripple {
  background: #616161;
}

filter-chip.active .mat-form-field .mat-form-field-ripple {
  background: #fff;
}

app-filter-chip .mat-form-field {
  font-size: 14px;
}

app-filter-chip .mat-form-field .mat-form-field-wrapper {
  padding-bottom: 0;
}

app-filter-chip .mat-form-field .mat-form-field-underline {
  bottom: 0;
}

app-filter-chip .mat-form-field .mat-form-field-infix {
  border-top: none;
  padding: 0.25em 0 0.5em 0;
}

app-filter-chip .mat-form-field .mat-form-field-flex {
  padding: .5em .5em 0 .5em;
  border-radius: 0;
}

filter-chip.active .mat-form-field .mat-form-field-flex {
  background: rgba(255, 255, 255, .08);
}
