// referenced in src/styles.scss

.mat-paginator-outer-container, .mat-paginator-container {
  height: 42px !important;
  min-height: 42px !important;
}

.mat-paginator-page-size {
  margin: -8px 8px;
  font-size: 14px
}

.mat-paginator-page-size-select .mat-select-trigger {
  font-size: 14px;
}

.mat-paginator-range-actions .mat-paginator-icon {
  margin-top: -3px;
}

.mat-paginator-container {
  flex-direction: row-reverse;
}

.mat-paginator-range-label {
  margin-left: 16px !important;
  margin-right: 0 !important;
  min-width: 96px;
  font-size: 14px;
}
