.mat-horizontal-content-container {
  padding: 0 24px !important;
  background: #00000070;
  border-radius: 0 0 0.5em 0.5em;
}

.mat-horizontal-stepper-header {
  height: 48px !important;
}

.mat-horizontal-stepper-header-container {
  background: white !important;
  border-radius: 0.5em 0.5em 0 0;
}

.mat-horizontal-stepper {
  background: #80808075 !important;
}

.mat-stepper-horizontal {
  background: #80808075 !important;
}
