// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// https://material.io/design/color/the-color-system.html#tools-for-picking-colors

// specific color for import
$primary-color: #3850DA;
$primary-color-light: rgba(56, 80, 218, 0.25);
;
$error-red: #D32F2F;
$selection-bg: #D7DBF3;
$selection-bg-border: #c1c5da;
$green: #4CAF50; //#15830C;
$green-selection-bg: #C8E6C9; //#D1E7B7;
$purple: #9C27B0;
$purple-selection-bg: #E1BEE7;
$red-700: #D32F2F;
$red-200: #EF9A9A;
$orange50-A700: #FF6D00;
$orange50-200: #FFCC80;
$dark-gray: #616161;
$medium-gray: #757575;
$medium-light-gray: #b8b8b8;
$light-gray: #e0e0e0;
$light-background: #f5f5f5;
$white: #fff;
