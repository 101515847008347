// referenced in src/styles.scss

/* .mat-checkbox-layout {
  display: flex !important;
  flex-grow: 1;
}

.mat-checkbox-label {
  flex-grow: 1;
} */

.mat-checkbox-inner-container {
  width: 18px !important;
  height: 18px !important;
}
