// referenced in src/styles.scss

.esri-view .esri-view-surface--inset-outline:focus::after {
  outline: none;
}

.esri-ui .esri-attribution {
  top: 0;
  bottom: auto;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.esri-attribution__sources {
  text-align: right;
  margin-left: 500px;
}

.esri-icon {
  font-weight: 500;
}

.esri-widget--button {
  color: rgba(0, 0, 0, 1);
  border-radius: 4px !important;
}

.esri-home {
  border-radius: 4px !important;
}

.esri-expand {
  border-radius: 4px !important;
}

.esri-zoom {
  border-radius: 4px !important;
}

.esri-zoom .esri-widget--button:last-child {
  border-top: none;
}

// button outlines
.esri-widget:focus-visible, .esri-widget:focus, .esri-widget *:focus-visible, .esri-widget *:focus {
  outline: none;
}


// default popups are hidden in map.service.ts using a watcher
// this style will hide popups for ALL map views, not just the main view
// .esri-view-root .esri-ui .esri-popup {
//   display: none;
// }

// .esri-popup__content .esri-feature__content-element {
//   padding: 0;
// }

// .esri-popup--shadow {
//   box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
// }
// .esri-popup, .esri-popup__main-container {
//   border-radius: 4px;
// }

// .esri-popup .esri-popup__main-container, .esri-popup .esri-popup__main-container .esri-widget__table {
//   font-family: 'Roboto';
// }
